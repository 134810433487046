import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NzTypographyModule } from 'ng-zorro-antd/typography';

import { AccountService } from '../../services/account.service';
import { takeUntil } from 'rxjs';

import { Account } from '../../models/account.model';
import { SubscribedComponent } from 'src/app/lib/subscribed.component';
import { ImageUrlPipe } from '../../pipes/image-url.pipe';

@Component({
  selector: 'app-header',
  standalone: true,
  templateUrl: './header.component.html',
  styleUrl: './header.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NzTypographyModule, ImageUrlPipe],
})
export class HeaderComponent extends SubscribedComponent implements OnInit {
  account: Account;

  constructor(
    private ref: ChangeDetectorRef,
    private accountService: AccountService,
  ) {
    super();
  }

  ngOnInit() {
    this.accountService.accountChanged.pipe(takeUntil(this.destroyed$)).subscribe((account) => {
      this.account = account;
      this.ref.markForCheck();
    });

    this.account = this.accountService.getAccount();
  }
}
