<div class="header">
  <!-- TODO: Add condition to show the business name only if there's no logo -->
  <!-- <div class="flex">
    <a href="#" target="_blank">
      <img src=".././../../assets/logo.svg" alt="logo" />
    </a>
  </div> -->
  <div class="flex">
    @if (account?.logoImageUuid) {
      <div class="flex max-h-12 items-center justify-center h-auto">
        <img
          [src]="account?.logoImageUuid | imageUrl: 'locationLogo'"
          alt="{{ account?.businessName }}"
          class="aspect-auto object-contain max-h-12"
        />
      </div>
    } @else {
      <h2 nz-typography>{{ account?.businessName }}</h2>
    }
  </div>
</div>
