import { Injectable } from '@angular/core';
import { ServiceItem } from '../models/service-item.model';
import { ServiceCategory } from '../models/service-category.model';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Choice } from '../models/choice.model';
import { Expert } from '../models/expert.model';
import { ExpertsService } from './experts.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ServiceService {
  private dataUrl: string = 'services/full/';
  private services: ServiceCategory[] = [];

  constructor(
    private readonly http: HttpClient,
    private expertsService: ExpertsService,
  ) {}

  getAllServices() {
    return this.services.slice();
  }

  getCategoryandServices(categoryId: number): ServiceCategory | undefined {
    return this.services.find((category) => category.id === categoryId);
  }

  storeServices(services: ServiceCategory[]) {
    this.services = services;
  }

  getService(id: number): ServiceItem | undefined {
    let service: ServiceItem | undefined = undefined;

    for (const category of this.services) {
      service = category.serviceItems.find((service) => service.id === id);
      if (service) {
        break;
      }
    }
    return service;
  }

  getChoice(id: number): Choice | undefined {
    console.log('Getting choice:', id);
    let choice: Choice;
    for (const category of this.services) {
      for (const service of category.serviceItems) {
        if (service.choices) {
          for (const choice of service.choices) {
            if (choice.id === id) {
              return choice;
            }
          }
        }
      }
    }

    console.log('Choice:', choice, typeof choice, choice[0]);
    return choice;
  }

  //Get the service item's choice with the minimum price
  getMinChoice(serviceId: number): Choice {
    const choices = this.getService(serviceId).choices;

    if (!choices.length) {
      return { id: null, name: null, duration: null, price: 0 };
    }

    return choices.reduce((minChoice, choice) => {
      return choice.price < minChoice.price ? choice : minChoice;
    });
  }

  // getAddon(id: number): Addon | undefined {
  //   let addon;
  //   for (let category of this.services) {
  //     for (let service of category.serviceItems) {
  //       addon = service.addons?.filter((addon) => addon.id === id);
  //       if (addon) {
  //         break;
  //       }
  //     }
  //   }
  //   return addon;
  // }

  // getAddonsForService(serviceItemId: number): ServiceItem[] {
  //   const service = this.getService(serviceItemId);
  //   let addons: ServiceItem[] = [];
  //   for (let addon of service.addons) {
  //     addons.push(this.getService(addon.addonServiceItemId));
  //   }
  //   console.log('Addons for service id ', serviceItemId, addons);
  //   return addons;
  // }

  fetchServices(locationUuid: string): Observable<ServiceCategory[]> {
    return this.http.get<ServiceCategory[]>(this.dataUrl + locationUuid).pipe(
      tap((services) => {
        services.forEach((category) => {
          category.serviceItems.forEach((item) => {
            this.text2Html(item);
          });
        });
        this.storeServices(services);
      }),
    );
  }

  text2Html(serviceItem: ServiceItem): ServiceItem {
    if (serviceItem.description) {
      serviceItem.description = serviceItem.description.replace(/(?:\r\n|\r|\n)/g, '<br>');
    }
    return serviceItem;
  }

  getExpertsForService(service: ServiceItem, includeAnyone = true): Expert[] {
    const experts: Expert[] = [];

    if (includeAnyone) {
      // TODO: implement this after MVP release
      // experts.push({ id: 0, firstName: 'Anyone', lastName: '' });
    }

    if (service.experts2Services) {
      experts.push(
        ...service.experts2Services
          .map((expert2service) => this.expertsService.getExpertDetails(expert2service.expertId))
          .filter((expert): expert is Expert => expert !== undefined),
      );
    }
    return experts;
  }
}
