import { Pipe, PipeTransform } from '@angular/core';
import { UploadCareConf } from 'src/app/config/constants';

@Pipe({
  name: 'imageUrl',
  standalone: true,
})
export class ImageUrlPipe implements PipeTransform {
  transform(imageUuid: string, type: string, size?: string): string {
    const cdnUrl = UploadCareConf.cdnUrl;
    let defaultImage: string;
    let options = '';

    switch (type) {
      case 'serviceCategoryList':
      case 'serviceItemList':
      case 'serviceItemDetails':
        options = '-/preview/640x480/';
        defaultImage = 'default-service.svg';
        break;

      case 'expertAvatar': {
        const imgSize = size === 'small' ? 44 : 80;
        options = `-/preview/${imgSize}x${imgSize}/`;
        defaultImage = 'default-expert.svg';
        break;
      }

      case 'locationLogo':
        options = '-/preview/200x200/';
        break;

      default:
        options = '-/preview/148x112/';
        defaultImage = 'default-service.svg';
    }

    return imageUuid ? `${cdnUrl}${imageUuid}/${options}` : `/assets/${defaultImage}`;
  }
}
