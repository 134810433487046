@if (!standaloneItems?.length) {
  <div class="flex flex-col items-center">
    <div class="text-5xl text-neutral-400">
      <span nz-icon nzType="calendar" nzTheme="outline"></span>
    </div>
    <h2 nz-typography class="mt-6" nzType="secondary">Book Your Appointment</h2>
    <div nz-typography nzType="secondary" class="mt-2 text-center">
      Looks like you haven't added any services yet. Let's get started!
    </div>
  </div>
} @else {
  <div *ngIf="appointment.date" class="mb-6 flex items-center">
    <div class="text-xl leading-3 text-neutral-600">
      <span nz-icon nzType="calendar" nzTheme="outline" class="mr-2"></span>
    </div>
    <h3 nz-typography>{{ appointment.date | date: dateTimeFormat() }}</h3>
  </div>
  <div class="space-y-4">
    @for (item of standaloneItems; track $index) {
      <div class="space-y-1">
        <!-- service name -->
        <div class="flex">
          <div nz-typography class="grow mr-2 font-semibold">{{ item.serviceName }}</div>
          <div nz-typography class="">{{ getTotalPriceForItem(item) | customCurrency }}</div>
        </div>
        <!-- choices -->
        @if (item.choiceId) {
          <div nz-typography class="grow">{{ item.choiceName }}</div>
        }
        <!-- addons -->
        @for (addon of getAddonsForItem(item); track $index) {
          <div nz-typography class="grow">{{ addon.serviceName }}</div>
        }
        <!-- expert & duration -->
        <div nz-typography class="grow">
          {{ getExpertDetails(item.expertId).displayName || getExpertDetails(item.expertId).firstName }} &middot;
          {{ getTotalDurationForItem(item) | customTime }}
        </div>
      </div>
    }
  </div>

  <nz-divider class="my-0"></nz-divider>

  <div>
    <div class="flex">
      <div nz-typography class="grow">Subtotal</div>
      <div nz-typography>{{ appointment.servicesTotal | customCurrency }}</div>
    </div>

    <div class="flex mt-1">
      <div nz-typography class="grow">Booking Fee (3.9%)</div>
      <div nz-typography>{{ appointment.bookingFee | customCurrency }}</div>
    </div>

    <div class="flex mt-4">
      <div nz-typography class="grow text-lg"><strong>Total Price</strong></div>
      <div nz-typography>
        <strong>{{ appointment.totalPrice | customCurrency }}</strong>
      </div>
    </div>
  </div>

  @if (showContinueButton) {
    <div class="mt-6 hidden lg:block">
      <button nzBlock nz-button nzType="primary" nzSize="large" (click)="onContinue()">{{ continueBtnLable }}</button>
    </div>
  }
}
